/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default class SEO extends React.Component {
  componentDidMount() {
    if (typeof document !== `undefined`) {
    }  
  }
  render() {
    const { color1 = 'red', color2 = 'blue' } = this.props  
  
    let img

    if (typeof document !== `undefined`) {
      if (!this.canvas) {
        this.canvas = document.createElement('canvas')
        this.canvas.width = 16
        this.canvas.height = 16  
      }
      this.context = this.canvas.getContext('2d')
      this.context.fillStyle = color1
      this.context.fillRect(0, 0, 16, 8)
      this.context.fillStyle = color2
      this.context.fillRect(0, 8, 16, 16)
      img = this.canvas.toDataURL()  
    }
  
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
                lang
              }
            }
          }
        `}
        render={ ({ site }) => (
          <Helmet
            htmlAttributes={{ lang: site.siteMetadata?.lang }}
            title={site.siteMetadata?.title}
            // titleTemplate={title ? `%s | ${title}` : null}
            titleTemplate={site.siteMetadata?.title ? `${site.siteMetadata?.title}` : null}
            meta={[
              {
                name: `description`,
                content: site.siteMetadata.description,
              },
              {
                property: `og:title`,
                content: site.siteMetadata?.title,
              },
              {
                property: `og:description`,
                content: site.siteMetadata.description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata?.author || ``,
              },
              {
                name: `twitter:title`,
                content: site.siteMetadata?.title,
              },
              {
                name: `twitter:description`,
                content: site.siteMetadata.description,
              },
            ]}
          >
            <link rel="icon" type="image/png" href={img || '/~/favicon/favicon.ico'} sizes="16x16"/>
          </Helmet>
        )}
      />    
    )  
  }
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}
