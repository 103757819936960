import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import shuffle from 'shuffle-array'
import queryString, { parse } from 'query-string'
import { PAGES, COLORS } from './constants'
import SEO from './seo'
import ReactComment from './reactComment'
import '../styles/menu.less'

const MainContext = React.createContext()
const INITIAL_MENU_RATIO = 0.7
const SMALL_MENU_RATIO = 0.3
const MIN_SECTION_WIDTH = 150
const NO_COLORS = 12
const FORBIDDEN_COLORS = [6, 8]
const COLOR_URL_SEPARATOR = '-'
const MOBILE_WIDTH_LIMIT = 640
const SCREENSAVER_FLASH_INTERVAL = 10000
const SCREENSAVER_MONITOR_INTERVAL = 1000
const SCREENSAVER_THRESHOLD = 50000

const getMenuState = (location) => {
  // if (location.pathname.indexOf('/media') == location.pathname.length - 6 && location.pathname.indexOf('/media') != -1)
  //   return { hasMenu: false, hasWork: false, hasInfo: false, hasShows: false }    

  if (location.pathname.indexOf('/shows') != -1 || location.pathname == '/' || location.pathname == '' || location.pathname == '/~' || location.pathname == '/~/')
    return { hasMenu: true, hasWork: true, hasInfo: true, hasShows: false }

  if (location.pathname.indexOf('/work') != -1)
    return { hasMenu: true, hasWork: false, hasInfo: true, hasShows: true }
  
if (location.pathname.indexOf('/info') != -1 || location.pathname.indexOf('/timeline') != -1) {
    return { hasMenu: true, hasWork: true, hasInfo: false, hasShows: true }
  }

  return { hasMenu: true, hasWork: true, hasInfo: false, hasShows: true }
}

export default class MenuContainer extends React.Component {
  state = {
    menuOnLeft: true,
    menuWidth: null,
    // strand: this.getRandomStrand(),
    colorScheme: this.getRandomColorScheme(),
    frameTitle: null,
    frameUrl: null,
    style: null,
    action: this,
    isClient: false,
    isMobile: false,
    lastEdited: null
  }
  constructor(props) {
    super(props)
    this._menuWidth = 100
    this._mouseMoveTimestamp = 0
    this._mouseMoveTimeout = null
    this._screensaverInterval = null
  }
  set menuWidth(s) {
    this._menuWidth = s
    let w = window.innerWidth < MOBILE_WIDTH_LIMIT ? window.innerWidth : Math.round(this._menuWidth)
    if (this._menuRef) {
      this._menuRef.style.width = `${w}px`
    }
    if (this._menuPlaceholderRef) {
      this._menuPlaceholderRef.style.width = this._menuRef.style.width
    }    
  }
  get menuWidth() { return this._menuWidth }
  onMenuMouseMove = (e) => {
    const { menuOnLeft } = this.state
    let sgn = menuOnLeft ? -1 : 1
    const dx = this._mouseMoveStartOffset - e.x
    this._mouseMoveStartOffset = e.x

    const newMenuWidth = this.menuWidth + sgn * dx    
    if (newMenuWidth < MIN_SECTION_WIDTH || newMenuWidth > window.innerWidth - MIN_SECTION_WIDTH) return
    this.menuWidth = newMenuWidth
  }
  onMenuMouseDown = (e) => {
    Array.prototype.slice.call(document.getElementsByTagName('iframe')).forEach(f => f.style.zIndex = -1)

    this._menuMouseDown = true
    this._mouseMoveStartOffset = e.x
    document.addEventListener('mousemove', this.onMenuMouseMove, false)

    document.getElementById('allContent').style['user-select'] = 'none';
  }
  onMenuMouseUp = (e) => {
    if (!this._menuMouseDown) return
    this._menuMouseDown = false
    document.removeEventListener('mousemove', this.onMenuMouseMove, false)
    Array.prototype.slice.call(document.getElementsByTagName('iframe')).forEach(f => f.style.zIndex = 'inherit')
    this.updateStateAndUrl({ menuWidth: this.menuWidth })
    document.getElementById('allContent').style['user-select'] = 'auto';
  }
  onWindowResize = (e) => {
    const ratio = (this.menuWidth / this.prevWindowSize)
    this.menuWidth = ratio * window.innerWidth
    this.prevWindowSize = window.innerWidth
    const currIsMobile = (window.innerWidth < MOBILE_WIDTH_LIMIT)
    if (currIsMobile != this.state.isMobile) {
      this.setState({ isMobile: currIsMobile })
    }
  }
  createStyles(s) {
    if (this.urlStylesheet) this.urlStylesheet.remove()
    this.urlStylesheet = document.createElement('style')
    this.urlStylesheet.innerHTML = s
    document.body.appendChild(this.urlStylesheet)
  }
  hashToState = (withStrand = true) => {
    const parsedParams = queryString.parse(window.location.hash)
    const newState = {}
    if (withStrand && parsedParams.strand) newState.colorScheme = this.strandToColorScheme(parsedParams.strand) //parsedParams.strand
    if (parsedParams.hemisphere) newState.menuOnLeft = (parsedParams.hemisphere == 'east')
    if (parsedParams.ratio && !isNaN(parseFloat(parsedParams.ratio))) {
      newState.menuWidth = parseFloat(parsedParams.ratio) * window.innerWidth
    } else {
      // newState.menuWidth = window.innerWidth * INITIAL_MENU_RATIO
    }
    if (parsedParams.frameTitle) newState.frameTitle = decodeURIComponent(parsedParams.frameTitle)
    if (parsedParams.frameUrl) newState.frameUrl = decodeURIComponent(parsedParams.frameUrl)
    if (parsedParams.style) newState.style = decodeURIComponent(parsedParams.style)

    return newState
  }
  startScreenSaver = () => {
    if (this._screensaverRunning) return
    this.setState({ screensaverActive: true }, () => {
      this._screensaverRunning = true
      this._screensaverInterval = setInterval(() => {
        this.updateStateAndUrl({ colorScheme: this.getRandomColorScheme() })
      }, SCREENSAVER_FLASH_INTERVAL)  
    })
  }
  stopScreenSaver = () => {
    if (!this._screensaverRunning) return
    if (this._screensaverInterval) clearInterval(this._screensaverInterval)
    this._screensaverInterval = null
    this._screensaverRunning = false
    this.setState({ screensaverActive: false })
  }
  startScreenSaverMonitor = () => {
    this._mouseMoveTimeout = setInterval(() => {
      const now = new Date().getTime()
      if (now - this._mouseMoveTimestamp > SCREENSAVER_THRESHOLD) {
        this.startScreenSaver()
      }
    }, SCREENSAVER_MONITOR_INTERVAL)
  }
  onDocumentMouseMove = (e) => {
    this.stopScreenSaver()
    this._mouseMoveTimestamp = new Date().getTime()
  }
  onKeyPress = (ev) => {
    if (ev.keyCode === 190 && !ev.altKey && (ev.metaKey || ev.ctrlKey)) {
      // Cmd + .
      ev.preventDefault();
      this.updateStateAndUrl({ colorScheme: this.getRandomColorScheme() })
    }
  }
  addHashToLocalLinks = () => {
    const links = [...document.getElementsByTagName('a')]    
    links.forEach(l => {
      const address = new URL(l)
      let isLocalLink = (l.href[0] == '/') || (address.host == window.location.host)
      if (address.pathname[1] != '~') isLocalLink = false
      if (isLocalLink) { l.setAttribute('href', address.origin + address.pathname + window.location.hash) }
    })
    //console.log(links)
  }
  componentDidMount() {
    fetch('/~/last-edited.txt').then((response) => {
      response.text().then(r => {
        if (r.length < 50)
          this.setState({ lastEdited: r })
      })
    })

    if (this._menuMoveRef) this._menuMoveRef.addEventListener('mousedown', this.onMenuMouseDown)
    document.addEventListener('mouseup', this.onMenuMouseUp)

    const parsedParams = queryString.parse(window.location.hash)
    const newState = this.hashToState() 

    if (newState.menuWidth)
      this.menuWidth = newState.menuWidth
      // this.menuWidth = newState.menuWidth || SMALL_MENU_RATIO * window.innerWidth
    if (parsedParams.section) {
      setTimeout(() => {
        let el = document.getElementById(parsedParams.section)
        if (el) el.scrollIntoView()  
      }, 100)
    }

    if (parsedParams.style) {
      this.createStyles(parsedParams.style)
    }

    window.addEventListener('hashchange', this.onHashChange)

    this.prevWindowSize = window.innerWidth
    window.addEventListener('resize', this.onWindowResize)
    document.addEventListener('keydown', this.onKeyPress)

    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
      // is mobile header
    } else {
      this._mouseMoveTimestamp = new Date().getTime()
      document.addEventListener('mousemove', this.onDocumentMouseMove)
      document.addEventListener('wheel', this.onDocumentMouseMove)
      this.startScreenSaverMonitor()
    }
    

    newState.isClient = true
    newState.isMobile = (window.innerWidth < MOBILE_WIDTH_LIMIT)
    this.updateStateAndUrl(newState, () => {
      this.addHashToLocalLinks();
    })
  }
  componentWillUnmount() {
    document.removeEventListener('mousemove', this.onDocumentMouseMove)
    document.removeEventListener('wheel', this.onDocumentMouseMove)

  }
  setSmallMenuRatioIfUnchanged = () => {
    if (!this.state.menuWidth) {
      this.menuWidth = SMALL_MENU_RATIO * window.innerWidth
    }
  }
  setLargeMenuRatioIfUnchanged = () => {
    if (!this.state.menuWidth) {
      this.menuWidth = INITIAL_MENU_RATIO * window.innerWidth
    }
  }
  onHashChange = () => {
    const newState = this.hashToState()
    if (newState.menuWidth != this.state.menuWidth) {
      this.menuWidth = newState.menuWidth
    }
    if (newState.style != this.state.style) {
      this.createStyles(newState.style)
    }
    this.setState(newState, () => {
      this.addHashToLocalLinks()
    })    
  }
  componentDidUpdate(oldProps) {
    if (oldProps.location.pathname != this.props.location.pathname) {
      this.updateStateAndUrl({ menuOnLeft: !this.state.menuOnLeft })
    }
  }
  updateStateAndUrl = (newState, cb) => {
    let qS = {}
    qS.hemisphere = (('menuOnLeft' in newState) ? newState : this.state).menuOnLeft ? 'east' : 'west'
    qS.strand = (('colorScheme' in newState) ? this.colorSchemeToStrand(newState.colorScheme) : this.colorSchemeToStrand(this.state.colorScheme))
    if (newState.menuWidth || this.state.menuWidth) qS.ratio = ((('menuWidth' in newState) ? newState : this.state).menuWidth / window.innerWidth).toFixed(3)
    if (newState.frameTitle || this.state.frameTitle) qS.frameTitle = encodeURI(newState.frameTitle || this.state.frameTitle)
    if (newState.frameUrl || this.state.frameUrl) qS.frameUrl = encodeURI(newState.frameUrl || this.state.frameUrl)
    if (newState.style || this.state.style) qS.style = encodeURI(newState.style || this.state.style)
    // window.location.hash = queryString.stringify(qS)
    window.location.replace('#' + queryString.stringify(qS))
    this.setState(newState, cb)
  }
  onMenuRef = (m) => {
    this._menuRef = m
  }
  getRandomColor() {
    let color = parseInt(Math.floor(Math.random() * NO_COLORS)) + 1
    while (FORBIDDEN_COLORS.includes(color))
      color = parseInt(Math.floor(Math.random() * NO_COLORS)) + 1
    return color
  }
  getRandomColorScheme() {
    let arr = Array.from({ length: NO_COLORS }, (_, i) => i + 1)
    let diff = arr.filter(x => !FORBIDDEN_COLORS.includes(x));
    shuffle(diff)
    return this.encodeColorScheme(diff[0], diff[1], diff[2])
  }
  encodeColorScheme(worksColor, showsColor, infoColor) {
    return `${worksColor}${COLOR_URL_SEPARATOR}${showsColor}${COLOR_URL_SEPARATOR}${infoColor}`
  }
  decodeColorScheme(str) {
    let [worksColor, showsColor, infoColor] = str.split(COLOR_URL_SEPARATOR)
    return {
      [PAGES.SHOWS]: parseInt(showsColor),
      [PAGES.WORKS]: parseInt(worksColor),
      [PAGES.INFO]: parseInt(infoColor)
    }
  }
  colorSchemeToStrand(c) {
    let o = this.decodeColorScheme(c)
    const menuState = getMenuState(this.props.location)
    if (!menuState.hasShows) {
      return this.encodeStrand(o[PAGES.WORKS], o[PAGES.INFO])
    } else if (!menuState.hasInfo) {
      return this.encodeStrand(o[PAGES.WORKS], o[PAGES.SHOWS])      
    } else {      
      return this.encodeStrand(o[PAGES.SHOWS], o[PAGES.INFO])
    }
  }
  strandToColorScheme(s) {
    const d = this.decodeStrand(s)
    const menuState = getMenuState(this.props.location)
    const currColors = this.decodeColorScheme(this.state.colorScheme)

    let index = 0
    let worksColor = menuState.hasWork ? d[index++] : currColors[PAGES.WORKS]
    let showsColor = menuState.hasShows ? d[index++] : currColors[PAGES.SHOWS]
    let infoColor = menuState.hasInfo ? d[index++] : currColors[PAGES.INFO]

    return this.encodeColorScheme(worksColor, showsColor, infoColor)
  }
  getRandomStrand() {
    let arr = Array.from({ length: NO_COLORS }, (_, i) => i + 1)
    let diff = arr.filter(x => !FORBIDDEN_COLORS.includes(x));
    shuffle(diff)
    return this.encodeStrand(diff[0], diff[1])
  }
  encodeStrand(firstColor, secondColor) {
    // return `${showsColor}${COLOR_URL_SEPARATOR}${worksColor}${COLOR_URL_SEPARATOR}${infoColor}`
    // return `${firstColor}${COLOR_URL_SEPARATOR}${secondColor}`
    return `${(firstColor - 1) * NO_COLORS + secondColor}`
  }
  decodeStrand(str) {
    const menuState = getMenuState(this.props.location)
    let strandId = parseInt(str)
    if (strandId > 144) strandId = 140
    let colors = [
      Math.floor((strandId - 1) / NO_COLORS) + 1,
      (strandId % NO_COLORS)
    ]
    if (colors[1] == 0) colors[1] = NO_COLORS

    return colors
  }
  getColorClassForPage = (page) => {
    const colors = this.decodeColorScheme(this.state.colorScheme)  

    if (colors[page]) return `color-${colors[page]}`
    console.warn('[getColorClassForPage]: Color scheme or page invalid', colors, page)
    return `color-${this.getRandomColor()}`
  }
  getFaviconColors = () => {
    const colorScheme = this.decodeColorScheme(this.state.colorScheme)
    const menuState = getMenuState(this.props.location)
    let colors = []
    if (menuState.hasWork) colors.push(COLORS[parseInt(colorScheme[PAGES.WORKS]) - 1])
    if (menuState.hasShows) colors.push(COLORS[parseInt(colorScheme[PAGES.SHOWS]) - 1])
    if (menuState.hasInfo) colors.push(COLORS[parseInt(colorScheme[PAGES.INFO]) - 1])
    return colors
  }
  render() {
    const context = { ...this.state }
    const { isClient } = this.state
    const { children, location } = this.props
    if (!location) return null

    const { menuOnLeft, isMobile } = this.state
    
    const menuCls = classnames({ menu: true, left: menuOnLeft, right: !menuOnLeft })
    const { hasWork, hasInfo, hasShows, hasMenu } = getMenuState(location)
    const showsCls = classnames({ "menu-item": true, "noselect": true, [this.getColorClassForPage(PAGES.SHOWS)]: true, 'transition-background-color': this._screensaverRunning })
    const worksCls = classnames({ "menu-item": true, "noselect": true, [this.getColorClassForPage(PAGES.WORKS)]: true, 'transition-background-color': this._screensaverRunning })
    const infoCls = classnames({ "menu-item": true, "noselect": true, [this.getColorClassForPage(PAGES.INFO)]: true, 'transition-background-color': this._screensaverRunning })
    const allContainerCls = classnames({
      "all-container": true,
      "hidden": !isClient
    })

    return (
      <MainContext.Provider value={context}>
        <ReactComment text="http://evan-roth.com/~/frame#frameUrl=http://gedym.in"/>
        <SEO color1={this.getFaviconColors()[0]} color2={this.getFaviconColors()[1]} />
        <main className={allContainerCls}>

          { (!menuOnLeft && !isMobile) && 
            <section className="all-content" id="allContent"> 
              { children } 
            </section>
          }

          { hasMenu &&
            <>
              { !isMobile && <div className="menu-placeholder" style={{width: isMobile ? '100%' : '40vw'}} ref={(m) => { this._menuPlaceholderRef = m }}></div> }
              <nav className={menuCls} style={{width: isMobile ? '100%' : '40vw'}} ref={(m) => { this._menuRef = m }}>
                { hasWork && isClient && <div className={worksCls}><h3><Link to="/work">Works</Link></h3></div> }
                { hasShows && isClient && <div className={showsCls}><h3><Link to="/shows">Shows</Link></h3></div> }
                { hasInfo && isClient && <div className={infoCls}><h3><Link to="/info">Info</Link></h3></div> }
                <div className="menu-drag-border" ref={(m) => { this._menuMoveRef = m }}></div>
              </nav>              
            </>
          }
    
          { (menuOnLeft || isMobile) && 
            <section className="all-content" id="allContent"> 
              { children } 
            </section>
          }
        </main>
      </MainContext.Provider>
    )  
  }
}

export const withMainContext = (mapping) => Component => props => {
  return (
      <MainContext.Consumer>
          {(context) => <Component {...props} {...mapping(context, props)}/>}
      </MainContext.Consumer>
  )
}

MenuContainer.defaultProps = {
  menuOnLeft: true
}