/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Import the component at the top of the file
import React from 'react'
import MenuContainer from './src/components/menuContainer'

export const wrapPageElement = ({ element, props }) => {
  return (
    <MenuContainer menuOnLeft={true} {...props}>{element}</MenuContainer>
  )
};