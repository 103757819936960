import ReactDOM from 'react-dom'

export const PAGES = {
  INFO: 'info',
  WORKS: 'work',
  SHOWS: 'shows'
}

export const navigateToId = (id) => () => {
  let el = document.getElementById(id)
  if (el) el.scrollIntoView()  
}

export const navigateToTop = (rEl) => () => {
  setTimeout(() => { 
    window.scroll(0, 0) 
    document.getElementById('allContent').scrollTo(0, 0) 
  }, 100)
}

export const COLORS = [
  '#2531f5',
  '#ef8732',
  '#75fb4c',
  '#964b00',
  '#737f8f',
  '#ffffff',
  '#e93423',
  '#000000',
  '#ffff55',
  '#8218f6',
  '#ed72b1',
  '#75fbfe'
]